import Page from '@pelckmans/elementis/components/page';
import { useContext } from 'react';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import TeacherMaterialSection from './components/teacher/MaterialSection';
import TocDataProvider from './_contexts/TocDataProvider';
import IdentityContext from '../../../contexts/IdentityContext';
import TableOfContentsContext from './_contexts/TableOfContentsContext';
import ModuleContext from '../_contexts/ModuleContext';
import StudentMaterialSections from './components/student/MaterialSections';

export function Toc() {
  const { t } = useTranslation();

  useDocumentTitle(t('MODULES.MATERIAL.BROWSE.STATE_TITLE'));

  const { module } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);
  const { identity } = useContext(IdentityContext);

  if (!module || !selectedNode) return null;

  return (
    <Page>
      <Page.Header headerText={selectedNode.title} backgroundImageUrl={module.styling?.visual} />
      <Page.Content>{identity.isTeacherAlike ? <TeacherMaterialSection /> : <StudentMaterialSections />}</Page.Content>
    </Page>
  );
}

export default function WrappedToc() {
  return (
    <TocDataProvider>
      <Toc />
    </TocDataProvider>
  );
}
