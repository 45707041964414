import Navigation from '@pelckmans/elementis/components/navigation';
import MethodsIcon from '@pelckmans/elementis/icons/methods';
import UsersIcon from '@pelckmans/elementis/icons/users';
import UserIcon from '@pelckmans/elementis/icons/user';
import { useRouting } from '../../routes';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import IdentityContext from '../../contexts/IdentityContext';

function Header() {
  const { getRoute } = useRouting();
  const { t } = useTranslation();

  const { identity } = useContext(IdentityContext);

  return (
    <header className="header">
      <Navigation>
        <Navigation.List orientation="horizontal">
          <Navigation.Item icon={<MethodsIcon />} to={`/ianua/${getRoute('MY_METHODS')}`}>
            {t('APP_NAV.TOP_NAV.MY_METHODS')}
          </Navigation.Item>

          {identity.isTeacherAlike ? (
            <Navigation.Item icon={<UsersIcon />} to={`/ianua/${getRoute('MY_GROUPS')}`}>
              {t('APP_NAV.TOP_NAV.MY_GROUPS')}
            </Navigation.Item>
          ) : (
            <Navigation.Item icon={<UsersIcon />} to={`/ianua/${getRoute('MY_TEACHERS')}`}>
              {t('APP_NAV.TOP_NAV.MY_TEACHERS')}
            </Navigation.Item>
          )}

          <Navigation.Item icon={<UserIcon />} to={`/ianua/${getRoute('MY_PROFILE')}`}>
            {t('APP_NAV.TOP_NAV.MY_PROFILE')}
          </Navigation.Item>
        </Navigation.List>
      </Navigation>
    </header>
  );
}

export default Header;
