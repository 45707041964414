import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Badge from '@pelckmans/elementis/components/badge';
import List from '@pelckmans/elementis/components/list';
import Section from '@pelckmans/elementis/components/section';

import { ContentType, Medialink } from '../../../../../modules/material/_api/MedialinksApi';
import { SharedSlideSet } from '../../../../../modules/material/_api/SlideSetApi';
import { SharedUserMaterial } from '../../../../../modules/material/_api/UserMaterialApi';
import SharedMaterialsContext from '../../_contexts/SharedMaterialsContext';
import { MaterialIcon } from '../MaterialIcon';

type SharedMaterialSectionProps = {
  medialinks: Medialink[];
};

type SharedMaterialListItem = {
  id: string;
  name: string;
  userId?: string;
  materialType: 'user-material' | 'medialink' | 'slide-set';
  contentType?: ContentType;
};

function mapMedialinkToListItem(medialinks: Medialink[]) {
  return medialinks.map(medialink => ({
    id: medialink.id,
    name: medialink.name,
    materialType: 'medialink' as const,
    contentType: medialink.contentType,
  }));
}

function filterAndMapSharedMaterialToListItem(
  sharedMaterials: (SharedUserMaterial | SharedSlideSet)[],
): SharedMaterialListItem[] {
  return sharedMaterials.reduce((list, material) => {
    if (material.materialType === 'user-material' && Boolean(material.assignment)) {
      return list;
    }

    list.push({
      id: 'versionId' in material ? `${material.id}-${material.versionId}` : material.id,
      name: material.name,
      materialType: material.materialType,
      userId: material.owner?.id,
    });

    return list;
  }, [] as SharedMaterialListItem[]);
}

function ContentTypeBadge({ material }: { material: SharedMaterialListItem }) {
  const { t } = useTranslation();
  const label =
    material.materialType === 'medialink'
      ? material.contentType?.name
      : t(`PAGES.TOC_LEVEL.SECTIONS.STUDENT.CONTENT_TYPE.${material.materialType.toUpperCase()}`);

  return (
    <Badge key={material.id} prominence="ghost" aria-label={label}>
      {label}
    </Badge>
  );
}

export default function SharedMaterialsSection({ medialinks }: SharedMaterialSectionProps) {
  const { t } = useTranslation();
  const { sharedMaterials } = useContext(SharedMaterialsContext);

  const noneAssignmentMedialinks = medialinks.filter(medialink => !Boolean(medialink.assignments.length));
  const allMaterials = filterAndMapSharedMaterialToListItem(sharedMaterials).concat(
    mapMedialinkToListItem(noneAssignmentMedialinks),
  );

  return (
    <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.STUDENT.SHARED.TITLE`)}>
      <List>
        {allMaterials.map(material => {
          return (
            <List.Item
              key={material.id}
              title={material.name}
              titleTag="h4"
              icon={<MaterialIcon materialType={material.materialType} isUserSlideSet={Boolean(material.userId)} />}
              badgeGroupProps={{
                role: 'group',
                'aria-label': t('PAGES.TOC_LEVEL.SECTIONS.STUDENT.SHARED.TAGS.A11Y_LABEL'),
                children: <ContentTypeBadge material={material} />,
              }}
            />
          );
        })}
      </List>
    </Section>
  );
}
