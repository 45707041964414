import Section from '@pelckmans/elementis/components/section';
import ListGroup from '@pelckmans/elementis/components/list-group';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';
import { Medialink } from '../../../../../modules/material/_api/MedialinksApi';
import MedialinkList from '../MedialinkList';

function sortAndGroupMedialinksByContentTyp(medialinks: Medialink[]) {
  return groupBy(
    sortBy(medialinks, 'contentType.category', 'contentType.name'),
    ({ contentType }) => contentType.category || contentType.name,
  );
}

function splitUpMedialinksInExerciseAndNoneExercise(medialinks: Medialink[]) {
  const [exerciseMedialinks, noneExerciseMedialinks] = medialinks.reduce<[Medialink[], Medialink[]]>(
    ([exerciseMedialinks, noneExerciseMedialinks], medialink) => {
      if (medialink.contentType.isExercise) {
        exerciseMedialinks.push(medialink);
      } else {
        noneExerciseMedialinks.push(medialink);
      }
      return [exerciseMedialinks, noneExerciseMedialinks];
    },
    [[], []],
  );

  return {
    exercisesByContentType: sortAndGroupMedialinksByContentTyp(exerciseMedialinks),
    noneExercisesByContentType: sortAndGroupMedialinksByContentTyp(noneExerciseMedialinks),
  };
}

type MedialinkSectionsProps = {
  medialinks: Medialink[];
};

export default function MedialinkSections({ medialinks }: MedialinkSectionsProps) {
  const { t } = useTranslation();

  const { exercisesByContentType, noneExercisesByContentType } = splitUpMedialinksInExerciseAndNoneExercise(medialinks);

  return (
    <>
      <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.STUDENT.EXERCISE.TITLE`)}>
        <ListGroup>
          {Object.keys(exercisesByContentType).map(category => (
            <MedialinkList key={category} medialinks={exercisesByContentType[category]} title={category} />
          ))}
        </ListGroup>
      </Section>
      <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.STUDENT.OTHER_MATERIALS.TITLE`)}>
        <ListGroup>
          {Object.keys(noneExercisesByContentType).map(category => (
            <MedialinkList key={category} medialinks={noneExercisesByContentType[category]} title={category} />
          ))}
        </ListGroup>
      </Section>
    </>
  );
}
