import { PropsWithChildren } from 'react';
import Sidebar from './sidebar/Sidebar.ianua';
import Header from './header/Header.ianua';

export default function Page({ children }: PropsWithChildren) {
  return (
    <div className="page">
      <Sidebar />
      <Header />
      <main>{children}</main>
    </div>
  );
}
