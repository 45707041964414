import List from '@pelckmans/elementis/components/list';
import type { ContentType } from '../../../../modules/material/_api/MedialinksApi';
import { MaterialIcon } from './MaterialIcon';

type MedialinkListItem = {
  id: string;
  name: string;
  contentType: ContentType;
};

type MedialinkListProps = {
  medialinks: MedialinkListItem[];
  title?: string;
};

export default function MedialinkList({ medialinks, title }: MedialinkListProps) {
  return (
    <List>
      {title && <List.Header title={title} titleTag="h3" prominent />}
      {medialinks.map(medialink => (
        <List.Item
          key={medialink.id}
          title={medialink.name}
          titleTag="h4"
          icon={<MaterialIcon materialType="medialink" contentType={medialink.contentType} />}
        />
      ))}
    </List>
  );
}
