import SelectField from '@pelckmans/elementis/components/select-field';
import { useTranslation } from 'react-i18next';
import { School, fetchSchools, fetchUserSchools, UserSchool } from '../../../api/MyGroupsApi';
import { useEffect, useMemo, useState } from 'react';
import useThrowAsyncError from '../../../../../hooks/useThrowAsyncError';
import debounce from 'lodash/debounce';
import SmartschoolIcon from '@pelckmans/elementis/icons/smartschool';

const renderSchoolSubText = ({ streetName, streetNumber, zipCode, city }: School['address']) => {
  return `${streetName} ${streetNumber}, ${zipCode} ${city}`;
};

type Props = {
  onChange: (school: UserSchool | null) => void;
  value: UserSchool | null;
  errorText?: string;
};

function SchoolSearchSelector({ onChange, value, errorText }: Props) {
  const { t } = useTranslation();
  const handleError = useThrowAsyncError();

  const [isLoading, setIsLoading] = useState(true);
  const [userSchools, setUserSchools] = useState<UserSchool[]>([]);
  const [schools, setSchools] = useState<School[]>([]);
  const [searchValue, onSearchValueChange] = useState('');

  const schoolsJsx = useMemo(() => {
    if (userSchools.length === 0 && schools.length === 0) {
      return [];
    }

    return (
      <>
        {Boolean(userSchools.length) && (
          <SelectField.Group
            title={t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.LISTS.MY_SCHOOLS.TITLE')}
          >
            {userSchools.map(userSchool => (
              <SelectField.Item
                key={userSchool.id}
                value={userSchool}
                selected={value?.id === userSchool.id}
                text={userSchool.name}
                subText={userSchool.isSmartschool ? `${userSchool.domain}` : renderSchoolSubText(userSchool.address)}
                icon={userSchool.isSmartschool ? <SmartschoolIcon /> : undefined}
              />
            ))}
          </SelectField.Group>
        )}
        {Boolean(schools.length) && (
          <SelectField.Group
            title={t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.LISTS.SCHOOLS.TITLE')}
          >
            {schools.map(school => (
              <SelectField.Item
                key={school.id}
                value={school}
                selected={value?.id === school.id}
                text={school.name}
                subText={renderSchoolSubText(school.address)}
              />
            ))}
          </SelectField.Group>
        )}
      </>
    );
  }, [schools, t, userSchools, value?.id]);

  const fetchLinkableSchoolsMatchingSearch = debounce(async (search?: string) => {
    try {
      const schoolsMatchingSearch = await fetchSchools(search);
      setSchools(schoolsMatchingSearch);

      const userSchoolsMatchingSearch = await fetchUserSchools(search);
      setUserSchools(userSchoolsMatchingSearch);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, 300);

  useEffect(() => {
    fetchLinkableSchoolsMatchingSearch(searchValue || undefined);

    return () => fetchLinkableSchoolsMatchingSearch.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <SelectField
      id="schools"
      required
      searchable
      value={value}
      onChange={onChange}
      errorText={errorText}
      isLoading={isLoading}
      renderValueContent={option => option.name}
      placeholder={t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.PLACEHOLDER')}
      labelText={t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.LABEL')}
      dropdownMenuProps={{
        ariaLabel: t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.A11Y_LABEL'),
      }}
      searchProps={{
        searchValue,
        onSearchValueChange,
        inputProps: {
          ariaLabel: t('PAGES.MY_GROUPS.MODALS.ADD_PERSONAL_GROUP.BODY.FORM.SCHOOL.SEARCH.A11Y_LABEL'),
        },
      }}
    >
      {schoolsJsx}
    </SelectField>
  );
}

export default SchoolSearchSelector;
