import List from '@pelckmans/elementis/components/list';
import { MaterialIcon } from './MaterialIcon';

type UserMaterialListItem = {
  id: string;
  name: string;
};

type UserMaterialListProps = {
  materials: UserMaterialListItem[];
  title: string;
};

export default function UserMaterialList({ materials, title }: UserMaterialListProps) {
  return (
    <List>
      {title && <List.Header title={title} titleTag="h3" prominent />}
      {materials.map(material => (
        <List.Item
          key={material.id}
          title={material.name}
          titleTag="h4"
          icon={<MaterialIcon materialType="user-material" />}
        />
      ))}
    </List>
  );
}
