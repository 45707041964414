import Dialog from '@pelckmans/elementis/components/dialog';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../../contexts/ModalContext';
import Button from '@pelckmans/elementis/components/button';
import { getAssignmentsCountForGroup, Group } from '../../api/MyGroupsApi';
import { SchoolYear } from '../../context/MyGroupsContext';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';

type Props = {
  id: string;
  schoolYear: SchoolYear;
  group: Group;
  onConfirm: () => Promise<void>;
};

function RemoveGroupConfirmationModal({ id, schoolYear, group, onConfirm }: Props) {
  const { t } = useTranslation();
  const handleError = useThrowAsyncError();

  const { closeModal } = useContext(ModalContext);

  const [assignmentsCount, setAssignmentsCount] = useState<number>();

  const handleClose = () => {
    closeModal(id);
  };

  const handleDelete = async () => {
    await onConfirm();
  };

  useEffect(() => {
    const getAssignmentsCount = async () => {
      const count = await getAssignmentsCountForGroup(schoolYear, group.id);

      setAssignmentsCount(count);
    };

    getAssignmentsCount().catch(handleError);
  }, [group, schoolYear, handleError]);

  if (assignmentsCount === undefined) return null;

  return (
    <Dialog
      id={id}
      modal
      size="sm"
      onClose={handleClose}
      aria-label={t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.A11Y_LABEL')}
    >
      <Dialog.Header
        title={t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.HEADER.TITLE')}
        onClose={handleClose}
        aria-label={t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.HEADER.ACTIONS.CLOSE')}
        danger
      />
      <Dialog.Body>
        {assignmentsCount > 0
          ? t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.BODY.TEXT_ASSIGNMENTS_IN_GROUP')
          : t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.BODY.TEXT')}
      </Dialog.Body>
      <Dialog.Footer
        buttonGroupPropsRight={{
          'aria-label': undefined,
          role: undefined,
          children: [
            <Button prominence="neutral" onClick={handleClose} key="cancel">
              {t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.FOOTER.ACTIONS.CANCEL')}
            </Button>,
            <Button prominence="danger" onClick={handleDelete} key="confirm">
              {t('PAGES.MY_GROUPS.MODALS.REMOVE_GROUP_CONFIRMATION.FOOTER.ACTIONS.CONFIRM')}
            </Button>,
          ],
        }}
      />
    </Dialog>
  );
}
export default RemoveGroupConfirmationModal;
