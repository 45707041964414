import Dialog from '@pelckmans/elementis/components/dialog';
import Button from '@pelckmans/elementis/components/button';
import TextField from '@pelckmans/elementis/components/text-field';
import { useToast } from '@pelckmans/elementis/components/toast';
import BarCode from '@pelckmans/elementis/icons/barcode';
import { useMaskito } from '@maskito/react';
import axios, { AxiosResponse } from 'axios';
import { FormEvent, FormEventHandler, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import ModalContext from '../../../contexts/ModalContext';

const FORM_ID = 'add-license-form';
const BASE = 'PAGES.MY_METHODS.ADD_LICENSE_MODAL';

type Props = {
  id: string;
  onRefetch: () => void;
};

function AddLicenseModal({ id, onRefetch }: Props) {
  const { addToast } = useToast();
  const { REACT_APP_P_STUDIO_GROUP } = process.env;
  const { t } = useTranslation();
  const [license, setLicense] = useState('');
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { closeModal } = useContext(ModalContext);

  const errorMessages = useMemo(() => {
    const result: Record<string, string> = {};

    if (error) result.license = t(`${BASE}.FORM.ERROR_MESSAGES.${error}`);

    if (license.length === 0) {
      result.license = t(`${BASE}.FORM.ERROR_MESSAGES.EMPTY`);
      return result;
    }
    if (license.length < 16) result.license = t(`${BASE}.FORM.ERROR_MESSAGES.INVALID`);

    return result;
  }, [license, t, error]);

  const isValid = useMemo(() => Object.keys(errorMessages).length === 0, [errorMessages]);

  const firstChar = useMemo(() => {
    return REACT_APP_P_STUDIO_GROUP === 'ES' ? /^[e]$/i : /^[pu]$/i;
  }, [REACT_APP_P_STUDIO_GROUP]);

  const secondChar = useMemo(() => {
    if (license.startsWith('P')) return /^[mM]$/;
    if (license.startsWith('U')) return /^[pP]$/;
    if (license.startsWith('E')) return /^[sS]$/;
    return /^[A-Za-z\d]$/;
  }, [license]);

  const chars = useMemo(() => (count: number) => Array(count).fill(/[A-Za-z\d]/), []);

  const mask = useMemo(
    () => [
      firstChar,
      secondChar,
      ' ',
      ...chars(4),
      '-',
      ...chars(4),
      '-',
      ...chars(4),
      '-',
      ...chars(4),
      '-',
      ...chars(4),
    ],
    [firstChar, secondChar, chars],
  );

  const inputRef = useMaskito({
    options: {
      mask,
    },
  });

  const handleClose = () => {
    closeModal(id);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    if (loading || !isValid) return;

    setLoading(true);
    setTouched(true);

    try {
      const code = license.replace(/[-\s]/g, '');

      await api.post<{ code: string }, AxiosResponse<{ needsRefreshToken?: boolean; licenseId: string }>>(
        '/studio/user/licenses/activate',
        { code },
      );

      onRefetch();

      addToast({
        variant: 'success',
        title: t(`${BASE}.SUCCESS_MESSAGE.TITLE`),
        children: t(`${BASE}.SUCCESS_MESSAGE.MESSAGE`),
      });

      handleClose();
    } catch (error) {
      setLoading(false);

      if (axios.isAxiosError(error) && error.response?.data && error.response.data.message) {
        const errorCode = error.response.data.message;

        if (error.response.status === 400) setError(errorCode);
      }
    }
  };

  return (
    <Dialog id={id} modal size="sm" onClose={handleClose} aria-label={t(`${BASE}.A11Y_LABEL`)}>
      <Dialog.Header
        title={t(`${BASE}.HEADER.TITLE`)}
        onClose={handleClose}
        aria-label={t(`${BASE}.HEADER.A11Y_LABEL`)}
      />
      <Dialog.Body>
        <form noValidate onSubmit={handleSubmit} id={FORM_ID} name={FORM_ID} className="add-license-modal__form">
          <TextField
            id="license"
            name="license"
            labelText={t(`${BASE}.FORM.LICENSE.LABEL`)}
            placeholder={t(`${BASE}.FORM.LICENSE.PLACEHOLDER`)}
            required
            maxLength={mask.length}
            startIcon={<BarCode />}
            ref={inputRef}
            value={license}
            onBlur={() => setTouched(true)}
            errorText={touched && errorMessages.license ? errorMessages.license : undefined}
            onInput={(e: FormEvent<HTMLInputElement>) => {
              setLicense(e.currentTarget.value.toUpperCase());
              setError(undefined);
            }}
          />
          <ul aria-label={t(`${BASE}.FORM.TIPS.A11Y_LABEL`)} className="add-license-modal__tips">
            <li>{t(`${BASE}.FORM.TIPS.TIP0`)}</li>
            <li>{t(`${BASE}.FORM.TIPS.TIP1`)}</li>
            <li>{t(`${BASE}.FORM.TIPS.TIP2`)}</li>
            <li>{t(`${BASE}.FORM.TIPS.TIP3`)}</li>
          </ul>
        </form>
      </Dialog.Body>
      <Dialog.Footer
        buttonGroupPropsRight={{
          role: 'group',
          'aria-label': t(`${BASE}.ACTIONS.A11Y_LABEL`),
          children: [
            <Button prominence="neutral" onClick={handleClose} key="cancel">
              {t(`${BASE}.ACTIONS.CANCEL`)}
            </Button>,
            <Button prominence="success" type="submit" form={FORM_ID} key="activate" disabled={loading}>
              {t(`${BASE}.ACTIONS.ACTIVATE`)}
            </Button>,
          ],
        }}
      />
    </Dialog>
  );
}

export default AddLicenseModal;
