import { useTranslation } from 'react-i18next';
import Section from '@pelckmans/elementis/components/section';
import ButtonDropdown from '@pelckmans/elementis/components/button-dropdown';
import Badge from '@pelckmans/elementis/components/badge';
import List from '@pelckmans/elementis/components/list';
import Pill from '@pelckmans/elementis/components/pill';
import SmartschoolIcon from '@pelckmans/elementis/icons/smartschool';
import PelckmansIcon from '@pelckmans/elementis/icons/pelckmans';
import ListGroup from '@pelckmans/elementis/components/list-group';
import { useContext } from 'react';
import useGroups, { SchoolYear } from '../hooks/useGroups';
import AddPersonalGroupModal from '../modals/add-personal-group/AddPersonalGroupModal';
import ModalContext from '../../../contexts/ModalContext';
import { Group } from '../api/MyGroupsApi';
import GroupDetailsModal from '../modals/group-details/GroupDetailsModal';

const renderSchoolNameWithCities = (school: Group['school']) => {
  if (school.cities) {
    return `${school.name}, ${school.cities}`;
  }

  return school.name;
};

type Props = {
  schoolYear: SchoolYear;
};

function OverviewMyGroups({ schoolYear }: Props) {
  const { groupsWithSchool, selectedSchoolYear, onGroupsChange } = useGroups();
  const { t } = useTranslation();
  const { showModal } = useContext(ModalContext);

  const addGroups = !schoolYear.hasPassed;

  const addGroupToState = (group: Group) => {
    onGroupsChange(prevGroups => {
      return (prevGroups || []).map(g => {
        if (g.id === group.id) {
          return group;
        }

        return g;
      });
    });
  };

  const removeGroupFromState = (group: Group) => {
    onGroupsChange(prevGroups => {
      return (prevGroups || []).filter(g => g.id !== group.id);
    });
  };

  return (
    <Section
      title={t('PAGES.MY_GROUPS.SECTION_TITLE', {
        schoolYearStartYear: schoolYear.startYear,
        schoolYearEndYear: schoolYear.endYear,
      })}
      actions={
        addGroups && selectedSchoolYear ? (
          <ButtonDropdown
            buttonProps={{ prominence: 'secondary', size: 'sm' }}
            title={t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_DROPDOWN.TITLE')}
          >
            <ButtonDropdown.Item
              onClick={() => {
                showModal(
                  <AddPersonalGroupModal
                    id="add-personal-group-modal"
                    schoolYear={selectedSchoolYear}
                    onGroupsChange={onGroupsChange}
                  />,
                );
              }}
              value="personal"
              text={t('PAGES.MY_GROUPS.ADD_GROUP.BUTTON_DROPDOWN.ITEMS.PERSONAL_GROUP')}
            />
          </ButtonDropdown>
        ) : undefined
      }
    >
      <ListGroup>
        {(groupsWithSchool || []).map(({ school, groups }) => {
          return (
            <List key={school.id}>
              <List.Header title={renderSchoolNameWithCities(school)} prominent titleTag="h3" />
              {groups.map(group => (
                <List.Item
                  key={group.id}
                  title={group.name}
                  titleTag="h4"
                  icon={group.smartschoolGroupId ? <SmartschoolIcon /> : <PelckmansIcon />}
                  onClick={() => {
                    if (!selectedSchoolYear) return;

                    showModal(
                      <GroupDetailsModal
                        id="group-details-modal"
                        group={group}
                        schoolYear={selectedSchoolYear}
                        editable={!selectedSchoolYear?.hasPassed}
                        onGroupRename={addGroupToState}
                        onGroupDelete={removeGroupFromState}
                      />,
                    );
                  }}
                  badgeGroupProps={{
                    role: 'group',
                    'aria-label': t('PAGES.MY_GROUPS.GROUP_LIST.STUDENTS.A11Y_LABEL'),
                    children: (
                      <>
                        {group.totalAccepted > 0 && (
                          <Badge
                            prominence="success"
                            aria-label={t('PAGES.MY_GROUPS.GROUP_LIST.STUDENTS.ACCEPTED.A11Y_LABEL', {
                              amount: group.totalAccepted,
                            })}
                          >
                            {group.totalAccepted}
                          </Badge>
                        )}
                        {group.totalPending > 0 && (
                          <Badge
                            prominence="warning"
                            aria-label={t('PAGES.MY_GROUPS.GROUP_LIST.STUDENTS.PENDING.A11Y_LABEL', {
                              amount: group.totalPending,
                            })}
                          >
                            {group.totalPending}
                          </Badge>
                        )}
                        {group.totalDeclined > 0 && (
                          <Badge
                            prominence="danger"
                            aria-label={t('PAGES.MY_GROUPS.GROUP_LIST.STUDENTS.DECLINED.A11Y_LABEL', {
                              amount: group.totalDeclined,
                            })}
                          >
                            {group.totalDeclined}
                          </Badge>
                        )}
                      </>
                    ),
                  }}
                  pillGroupProps={{
                    role: 'group',
                    'aria-label': t('PAGES.MY_GROUPS.GROUP_LIST.SUBJECTS.A11Y_LABEL'),
                    children: group.subjects.map((s, i) => <Pill key={`${i}-${s}`}>{s}</Pill>),
                  }}
                />
              ))}
            </List>
          );
        })}
      </ListGroup>
    </Section>
  );
}

export default OverviewMyGroups;
