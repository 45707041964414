import { useRouting } from '../../routes';
import { useTranslation } from 'react-i18next';
import Navigation from '@pelckmans/elementis/components/navigation';
import PelckmansIcon from '@pelckmans/elementis/icons/pelckmans';

function Sidebar() {
  const { getRoute } = useRouting();
  const { t } = useTranslation();

  return (
    <aside className="sidebar">
      <div className="top-navigation">
        <Navigation>
          <Navigation.List>
            <Navigation.Item icon={<PelckmansIcon />} iconOnly to={`/ianua/${getRoute('MY_METHODS')}`}>
              {t('APP_NAV.SIDE_NAV.HOME')}
            </Navigation.Item>
          </Navigation.List>
        </Navigation>
      </div>
    </aside>
  );
}

export default Sidebar;
