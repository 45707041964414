import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ListGroup from '@pelckmans/elementis/components/list-group';
import Section from '@pelckmans/elementis/components/section';

import MedialinksContext from '../../_contexts/MedialinksContext';
import SlideSetsContext from '../../_contexts/SlideSetsContext';
import UserMaterialsContext from '../../_contexts/UserMaterialsContext';
import MedialinkList from '../MedialinkList';
import UserMaterialList from '../UserMaterialList';
import SlideSetList from '../SlideSetList';

export default function MaterialSection() {
  const { t } = useTranslation();
  const { medialinks } = useContext(MedialinksContext);
  const { userMaterials } = useContext(UserMaterialsContext);
  const { slideSets } = useContext(SlideSetsContext);

  const medialinksSortedByContentType = sortBy(medialinks, 'contentType.category', 'contentType.name');

  const medialinksSortedByCategory = groupBy(
    medialinksSortedByContentType,
    ({ contentType }) => contentType.category || contentType.name,
  );

  return (
    <Section title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.TITLE`)}>
      <ListGroup>
        <UserMaterialList
          key="user-materials"
          materials={userMaterials}
          title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.SUB_TITLE_OWN_MATERIALS`)}
        />
        <SlideSetList
          key="slide-sets"
          slideSets={slideSets}
          title={t(`PAGES.TOC_LEVEL.SECTIONS.TEACHER.MATERIALS.SUB_TITLE_SLIDE_SETS`)}
        />
        {Object.keys(medialinksSortedByCategory).map(category => (
          <MedialinkList key={category} medialinks={medialinksSortedByCategory[category]} title={category} />
        ))}
      </ListGroup>
    </Section>
  );
}
