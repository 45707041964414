import Button from '@pelckmans/elementis/components/button';
import Dialog from '@pelckmans/elementis/components/dialog';
import TrashIcon from '@pelckmans/elementis/icons/trash';
import { FormEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../../../contexts/ModalContext';
import { deleteGroup, Group, renameGroup } from '../../api/MyGroupsApi';
import { useFormHandling } from '@pelckmans/business-components/hooks/useFormHandling';
import SelectField from '@pelckmans/elementis/components/select-field';
import TextField from '@pelckmans/elementis/components/text-field';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import { SchoolYear } from '../../context/MyGroupsContext';
import RemoveGroupConfirmationModal from '../remove-group-confirmation/RemoveGroupConfirmationModal';
import { useToast } from '@pelckmans/elementis/components/toast';

const FORM_ID = 'group-details-form';
const REMOVE_GROUP_CONFIRMATION_MODAL_ID = 'remove-group-confirmation-modal';

type FormValues = {
  groupName: string;
};

type Props = {
  id: string;
  group: Group;
  schoolYear: SchoolYear;
  editable: boolean;
  onGroupRename: (groupWithNewName: Group) => void;
  onGroupDelete: (deletedGroup: Group) => void;
};

function GroupDetailsModal({ id, group, schoolYear, editable, onGroupRename, onGroupDelete }: Props) {
  const { t } = useTranslation();
  const handleError = useThrowAsyncError();
  const { addToast } = useToast();

  const { closeModal, showModal } = useContext(ModalContext);

  const [submitted, setSubmitted] = useState(false);

  const handleClose = () => {
    closeModal(id);
  };

  const validateForm = useCallback(
    (values: FormValues) => {
      const result: Record<string, string> = {};

      const { groupName } = values;

      if (editable && groupName?.length === 0) {
        result.groupName = t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.GROUP.ERROR');
      }

      return result;
    },
    [t, editable],
  );

  const initialFormValues: FormValues = useMemo(
    () => ({
      groupName: group.name,
    }),
    [group.name],
  );

  const { formValues, touched, errors, handleBlur, handleChange, isFormValid } = useFormHandling(
    initialFormValues,
    validateForm,
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();

    setSubmitted(true);

    if (!isFormValid) return;

    if (!touched.groupName) {
      handleClose();

      return;
    }

    try {
      const groupWithNewName = await renameGroup(schoolYear, group.id, formValues.groupName);

      onGroupRename(groupWithNewName);

      handleClose();
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitted(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteGroup(schoolYear, group.id);

      onGroupDelete(group);

      closeModal(REMOVE_GROUP_CONFIRMATION_MODAL_ID);

      handleClose();

      addToast({
        variant: 'success',
        title: t('PAGES.MY_GROUPS.TOASTS.REMOVE_GROUP.SUCCESS.TITLE'),
        children: t('PAGES.MY_GROUPS.TOASTS.REMOVE_GROUP.SUCCESS.TEXT', { groupName: group.name }),
      });
    } catch (err) {
      handleError(err);
    }
  };

  const openConfirmationModal = () => {
    showModal(
      <RemoveGroupConfirmationModal
        id={REMOVE_GROUP_CONFIRMATION_MODAL_ID}
        group={group}
        schoolYear={schoolYear}
        onConfirm={handleDelete}
      />,
    );
  };

  return (
    <Dialog
      id={id}
      modal
      size="sm"
      onClose={handleClose}
      aria-label={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.A11Y_LABEL')}
    >
      <Dialog.Header
        title={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.HEADER.TITLE', { groupName: group.name })}
        onClose={handleClose}
        aria-label={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.HEADER.ACTIONS.CLOSE')}
      />
      <Dialog.Body>
        <form noValidate id={FORM_ID} name={FORM_ID} onSubmit={handleSubmit} className="group-details-form">
          <span className="modal-content-title">{t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.TITLE')}</span>
          <div className="form-fields">
            <TextField
              id="groupName"
              name="groupName"
              labelText={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.GROUP.LABEL')}
              placeholder={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.GROUP.PLACEHOLDER')}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              errorText={(touched.groupName || submitted) && errors.groupName ? errors.groupName : undefined}
              maxLength={25}
              disabled={!editable}
              value={formValues.groupName}
            />
            <SelectField
              id="schools"
              disabled
              required
              onChange={() => {}}
              value={group.school}
              renderValueContent={option => option.name}
              labelText={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SCHOOL.LABEL')}
              placeholder={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SCHOOL.PLACEHOLDER')}
              dropdownMenuProps={{
                ariaLabel: t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SCHOOL.A11Y_LABEL'),
              }}
            />
            <SelectField
              id="subjects"
              disabled
              required
              multiple
              onChange={() => {}}
              value={group.subjects}
              renderValueContent={option => option}
              labelText={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SUBJECT.LABEL')}
              placeholder={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SUBJECT.PLACEHOLDER')}
              dropdownMenuProps={{
                ariaLabel: t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SUBJECT.A11Y_LABEL'),
              }}
              selectedValueLabel={t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.BODY.FORM.SUBJECT.SELECTED_LABEL')}
            />
          </div>
        </form>
      </Dialog.Body>
      <Dialog.Footer
        buttonGroupPropsLeft={{
          'aria-label': undefined,
          role: 'group',
          children: (
            <Button prominence="danger" startIcon={<TrashIcon />} onClick={openConfirmationModal}>
              {t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.FOOTER.ACTIONS.DELETE')}
            </Button>
          ),
        }}
        buttonGroupPropsRight={{
          'aria-label': undefined,
          role: 'group',
          children: editable ? (
            [
              <Button prominence="neutral" onClick={handleClose} key="cancel">
                {t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.FOOTER.ACTIONS.CANCEL')}
              </Button>,
              <Button prominence="success" type="submit" form={FORM_ID} key="submit">
                {t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.FOOTER.ACTIONS.SUBMIT')}
              </Button>,
            ]
          ) : (
            <Button prominence="neutral" onClick={handleClose}>
              {t('PAGES.MY_GROUPS.MODALS.GROUP_DETAILS.FOOTER.ACTIONS.CLOSE')}
            </Button>
          ),
        }}
      ></Dialog.Footer>
    </Dialog>
  );
}

export default GroupDetailsModal;
