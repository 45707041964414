import orderBy from 'lodash/orderBy';
import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import FeatureTogglesContext from '../../../../contexts/FeatureTogglesContext';
import IdentityContext from '../../../../contexts/IdentityContext';
import useThrowAsyncError from '../../../../hooks/useThrowAsyncError';
import ModuleContext from '../../_contexts/ModuleContext';
import TableOfContentsContext from './TableOfContentsContext';
import {
  ListSharedSlideSetsQuery,
  SharedSlideSet,
  getSharedSlideSets,
} from '../../../../modules/material/_api/SlideSetApi';
import { SharedUserMaterial, getSharedUserMaterialWithScores } from '../../../../modules/material/_api/UserMaterialApi';

type FetchSharedMaterials = (opts?: Omit<ListSharedSlideSetsQuery, 'nodeId'>) => Promise<void>;

type SharedMaterialsContextValue = {
  sharedMaterials: (SharedUserMaterial | SharedSlideSet)[];
  fetchSharedMaterials: FetchSharedMaterials;
  isLoading: boolean;
};

const SharedMaterialsContext = createContext<SharedMaterialsContextValue>({
  sharedMaterials: [],
  fetchSharedMaterials: () => Promise.resolve(),
  isLoading: false,
});

export default SharedMaterialsContext;

export function SharedMaterialsContextProvider({ children }: PropsWithChildren) {
  const handleError = useThrowAsyncError();

  const { identity } = useContext(IdentityContext);
  const { selectedModule } = useContext(ModuleContext);
  const { selectedNode } = useContext(TableOfContentsContext);
  const featureToggles = useContext(FeatureTogglesContext);

  const [sharedMaterials, setSharedMaterials] = useState<(SharedUserMaterial | SharedSlideSet)[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const sortSharedMaterials = useCallback(<T,>(materials: T[]) => {
    return orderBy(materials, ['materialType', 'lastUpdatedAt'], ['desc', 'asc']);
  }, []);

  const fetchSharedMaterials = useCallback<FetchSharedMaterials>(
    async options => {
      if (!selectedModule || !selectedNode) return;
      setIsLoading(true);
      try {
        const [sharedUserMaterialResponse, sharedSlideSetsResponse] = await Promise.all([
          getSharedUserMaterialWithScores(selectedModule.id, selectedNode.id),
          featureToggles.SHARE_SLIDE_SET
            ? getSharedSlideSets(selectedModule.id, { nodeId: selectedNode.id, ...(options || {}) })
            : Promise.resolve({ data: [] }),
        ]);

        setSharedMaterials(sortSharedMaterials([...sharedUserMaterialResponse.data, ...sharedSlideSetsResponse.data]));
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [featureToggles.SHARE_SLIDE_SET, handleError, selectedModule, selectedNode, sortSharedMaterials],
  );

  useEffect(() => {
    if (identity.isAuthenticated && !identity.isTeacherAlike) fetchSharedMaterials();
  }, [fetchSharedMaterials, identity.isAuthenticated, identity.isTeacherAlike]);

  return (
    <SharedMaterialsContext.Provider
      value={{
        sharedMaterials,
        fetchSharedMaterials,
        isLoading,
      }}
    >
      {children}
    </SharedMaterialsContext.Provider>
  );
}
