import List from '@pelckmans/elementis/components/list';
import { MaterialIcon } from './MaterialIcon';

type SlideSetListItem = {
  id: number;
  name: string;
  versionId: number;
  userId?: number;
};

type SlideSetListProps = {
  slideSets: SlideSetListItem[];
  title?: string;
};

export default function SlideSetList({ slideSets, title }: SlideSetListProps) {
  return (
    <List>
      {title && <List.Header title={title} titleTag="h3" prominent />}
      {slideSets.map(slideSet => (
        <List.Item
          key={`${slideSet.id}-${slideSet.versionId}`}
          title={slideSet.name}
          titleTag="h4"
          icon={<MaterialIcon materialType="slide-set" isUserSlideSet={Boolean(slideSet.userId)} />}
        />
      ))}
    </List>
  );
}
