import { PropsWithChildren } from 'react';
import { TableOfContentsContextProvider } from './TableOfContentsContext';
import { MedialinksContextProvider } from './MedialinksContext';
import { SlideSetsContextProvider } from './SlideSetsContext';
import { UserMaterialsContextProvider } from './UserMaterialsContext';
import { SharedMaterialsContextProvider } from './SharedMaterialsContext';

export default function TocDataProvider({ children }: PropsWithChildren) {
  return (
    <TableOfContentsContextProvider>
      <SharedMaterialsContextProvider>
        <MedialinksContextProvider>
          <UserMaterialsContextProvider>
            <SlideSetsContextProvider>{children}</SlideSetsContextProvider>
          </UserMaterialsContextProvider>
        </MedialinksContextProvider>
      </SharedMaterialsContextProvider>
    </TableOfContentsContextProvider>
  );
}
