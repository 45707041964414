import api, { ResponsePayloadWithData } from '../../../api';
import { SchoolYear } from '../../../modules/my-groups/contexts/GroupsContext';

type CreateGroupRequestPayload = {
  schoolYear: SchoolYear;
  name: string;
  school: {
    id: string;
    isSmartschool: boolean;
  };
  subjects: string[];
};

type SchoolInGroup = {
  id: string;
  name: string;
  cities?: string;
  isSmartschool: boolean;
};

type GroupStatus = 'unpublished' | 'published' | 'closed';

export type Group = {
  id: string;
  name: string;
  school: SchoolInGroup;
  subjects: string[];
  schoolYear: string;
  totalAccepted: number;
  totalPending: number;
  totalDeclined: number;
  invitationId: string;
  smartschoolGroupId?: string;
  status?: GroupStatus;
  code?: string;
  startDate?: string;
  endDate?: string;
};

export async function createGroup({ name, school, schoolYear, subjects }: CreateGroupRequestPayload): Promise<Group> {
  const { data } = await api.post<Group>(
    `/studio/user/school-years/${schoolYear.startYear}-${schoolYear.endYear}/groups`,
    {
      name,
      school,
      subjects,
    },
    { params: { studioGroup: process.env.REACT_APP_P_STUDIO_GROUP } },
  );

  return data;
}

export async function fetchGroups(schoolYear: SchoolYear): Promise<Group[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Group[]>>(
    `studio/user/school-years/${schoolYear.startYear}-${schoolYear.endYear}/groups`,
    {
      params: { studioGroup: process.env.REACT_APP_P_STUDIO_GROUP },
    },
  );

  return data;
}

export type UserSchoolYearResponse = {
  startYear: number;
  endYear: number;
  isCurrent: boolean;
  hasPassed: boolean;
};

export async function fetchUserSchoolYears(): Promise<UserSchoolYearResponse[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<UserSchoolYearResponse[]>>('/studio/user/school-years');

  return data;
}

export type SchoolResponse = {
  id: string;
  name: string;
  address: {
    city: string;
    streetName: string;
    streetNumber: string;
    zipCode: string;
  };
};

export type School = SchoolResponse & { isSmartschool: false };

export async function fetchSchools(search?: string): Promise<School[]> {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<SchoolResponse[]>>('/studio/schools', { params: { search } });

  return data.map(x => ({ ...x, isSmartschool: false }));
}

export type Subject = {
  id: string;
  name: string;
  slug: string;
  published: boolean;
};

export async function fetchSubjects() {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<Subject[]>>('/studio/subjects/sanitized', {
    params: { group: process.env.REACT_APP_P_STUDIO_GROUP },
  });

  return data;
}

export type SmartschoolSchool = {
  id: string;
  name: string;
  domain: string;
  isSmartschool: true;
};

export type UserSchool = School | SmartschoolSchool;

export async function fetchUserSchools(search?: string) {
  const {
    data: { data },
  } = await api.get<ResponsePayloadWithData<UserSchool[]>>('/studio/user/schools', { params: { search } });

  return data;
}

export async function renameGroup(schoolYear: SchoolYear, groupId: string, name: string): Promise<Group> {
  const { data } = await api.patch<Group>(
    `studio/user/school-years/${schoolYear.startYear}-${schoolYear.endYear}/groups/${groupId}`,
    { name },
  );

  return data;
}

export async function deleteGroup(schoolYear: SchoolYear, groupId: string): Promise<void> {
  await api.delete(`studio/user/school-years/${schoolYear.startYear}-${schoolYear.endYear}/groups/${groupId}`);
}

export async function getAssignmentsCountForGroup(schoolYear: SchoolYear, groupId: string): Promise<number> {
  const {
    data: {
      data: { count },
    },
  } = await api.get<ResponsePayloadWithData<{ count: number }>>(
    `studio/user/school-years/${schoolYear.startYear}-${schoolYear.endYear}/groups/${groupId}/assignments/count`,
  );

  return count;
}
