import { useContext } from 'react';
import MedialinksContext from '../../_contexts/MedialinksContext';
import MedialinkSections from './MedialinkSections';
import SharedMaterialSection from './SharedMaterialSection';
import { Medialink } from '../../../../../modules/material/_api/MedialinksApi';

function splitUpTeacherAndStudentMedialinks(medialinks: Medialink[]) {
  return medialinks.reduce(
    (acc: { teacherMedialinks: Medialink[]; studentMedialinks: Medialink[] }, medialink) => {
      if (medialink.group === 'teacher') {
        acc.teacherMedialinks.push(medialink);
      } else {
        acc.studentMedialinks.push(medialink);
      }
      return acc;
    },
    { teacherMedialinks: [], studentMedialinks: [] },
  );
}

export default function MaterialSections() {
  const { medialinks } = useContext(MedialinksContext);

  const { teacherMedialinks, studentMedialinks } = splitUpTeacherAndStudentMedialinks(medialinks);

  return (
    <>
      <SharedMaterialSection medialinks={teacherMedialinks} />
      <MedialinkSections medialinks={studentMedialinks} />
    </>
  );
}
